<template>
  <section class="player-window">
    <div :class="['player-window__wrapper', isWinnerClasses]">
      <BattlesEmojiPickerEmojiAnimator
        as="div"
        class="player-window__content"
        :emojies="props.emojies"
        :start-cords="{ x: 60, y: 20 }"
        @on-emoji-ends="$emit('on-emoji-ends', $event)"
      >
        <BattlesEmojiPicker
          v-if="canShowEmodji"
          :is-static="false"
          :open-on-render="!device.isMobileOrTablet"
          :direction="changeXL('vertical', 'horizontal')"
          @on-chosen="$emit('send-emodji', $event as string)"
        />
        <SharedKitButton
          v-if="canLeave && battleState.created"
          class="player-window__content-leave--desktop"
          :is-loading="props.buttonsState.leaving"
          :is-disabled="isHostClicked && !battleState.finished"
          v-bind="presetLeaveBtn('secondary')"
          :text="$t('battles.playerWindow.quit')"
          @click.once="$emit('leave')"
        >
          <template #prepend>
            <SvgoPlayerExitIcon :width="16" :height="16" />
          </template>
        </SharedKitButton>
        <CreatedStatus v-if="battleState.created" :is-ready="isPlayer" :ready="ready" />
        <LoadingStatus
          v-if="
            (battleState.started && isInterrupted) ||
            isNotReadyToStart ||
            (isBattleAtStartPoint && !battleState.created)
          "
        />
        <ActionButtons
          v-if="showActionButtons"
          :buttons-state="buttonsState"
          :is-host="isHost"
          :is-me="isMe"
          :has-players="hasPlayers"
          :offer="playerOffer"
          :is-waiting="isStartClickedWhenNoPlayers"
          @start="handleHostStart"
          @start-bots="handleHostStartBots"
          @offer-start="$emit('offer-start')"
          @offer-start-bots="$emit('offer-start-bots')"
          @cancel-offer="$emit('cancel-offer')"
          @cancel-waiting="isStartClickedWhenNoPlayers = false"
        />
        <ActionButtons
          v-if="showOffer"
          :buttons-state="buttonsState"
          :is-host="isHost"
          :is-me="isMe"
          :has-players="hasPlayers"
          :offer="readyOtherPlayers"
        />
        <LazyClientOnly>
          <template v-if="!isInterrupted && !isNotReadyToStart && !isBattleAtStartPoint">
            <CasesSpinner
              v-if="!battleState.created && !showResults && !multicastMode"
              :player-slot="player?.slot || 0"
              :items="spinnerItems"
              :drops="wonDrop"
              :duration="animator.duration"
              :number="animator.round"
              :is-won="isRoundWon"
              :is-last-round="isLastRound"
              :round-prizes-percent-of-total="roundDropPercentOfTotalSum"
              @on-next-item="$emit('round-next-item')"
              @end-spin="$emit('round-end-spin')"
              @end="$emit('round-end')"
              @start-round="$emit('start-round')"
              @end-battle-animate-inventory="lastRoundSpinnerFinished = true"
            />
            <BattlesPlayerWindowMultiNew
              v-else-if="!battleState.created && !showResults && multicastMode"
              :drops="wonDrop"
              :is-last-round="isLastRound"
              :is-won="isRoundWon"
              :number="animator.round"
              :round-prizes-percent-of-total="roundDropPercentOfTotalSum"
              @end="$emit('round-end')"
              @fan="$emit('start-fan')"
              @circle="$emit('start-circle')"
              @end-battle-animate-inventory="lastRoundSpinnerFinished = true"
            ></BattlesPlayerWindowMultiNew>
          </template>
        </LazyClientOnly>
        <Transition @after-enter="checkPlayerStatus()">
          <EndStatus
            v-if="showResults"
            :is-draw="isDraw"
            :is-lose-draw="isPlayerLose"
            :is-won="isPlayerWon"
            :is-lost="isPlayerLost"
            :sum="playerDropsSum"
            :total="playerTotalWon ?? 0"
          />
        </Transition>
      </BattlesEmojiPickerEmojiAnimator>
      <div class="player-window__line">
        <SharedKitButton
          v-if="isJoinnable"
          class="player-window__line-join"
          :text="$t('battles.playerWindow.join')"
          :is-loading="!!props.buttonsState.joining"
          :is-disabled="!!props.buttonsState.joining && props.player?.slot !== props.buttonsState.joining"
          v-bind="presetBtn('primary')"
          @click="$emit('join')"
        />
        <SharedText v-else-if="isWaiting" class="player-window__line-waiting" v-bind="textStyles">
          {{ $t('battles.playerWindow.waitingForPlayer') }}
        </SharedText>
        <PlayerLine
          v-else
          :class="{ 'line-color': isChangeColorSums }"
          :player="player!"
          :is-created="battleState.created"
          :is-host="isHost"
          :offer-type="playerOffer"
          :sum="formattedSum"
          :sum-win="playerTempWin"
        />
      </div>
    </div>

    <div v-if="showDrop" :class="['player-window__drops', isDropsShowClasses]">
      <TransitionGroup appear :css="!isGoFromLink" name="battles-drops">
        <SharedKitDropItem
          v-for="drop in playerDropsShow"
          :key="drop.key"
          class="player-window__drop"
          :name="drop.drop.name"
          :image="declareImageOnItem(drop.drop)"
          :color="declareColorOfItem(drop.drop)"
          :price="String(drop.drop.price)"
        />
      </TransitionGroup>
      <LoadingStatus v-if="isLoadingAfterReload" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { BattlesColors } from '../../constants/colors';
import type { IPlayerWindowEmits, IPlayerWindowProps, IShowDrops } from './PlayerWindow.types';

import PlayerLine from './PlayerLine/PlayerLine.vue';
import CreatedStatus from './CreatedStatus/CreatedStatus.vue';
import ActionButtons from './ActionButtons/ActionButtons.vue';
import CasesSpinner from './CasesSpinner/CasesSpinner.vue';
import EndStatus from './EndStatus/EndStatus.vue';
import LoadingStatus from './LoadingStatus/LoadingStatus.vue';
import { Colors } from '~/constants/colors.constants';
import { FontSizesNumber, FontWeights } from '~/types/SharedFont.types';
import { createPresetor } from '~/features/battles/presets/buttons.presets';
import type { IRoundSkin } from '~/features/battles/types/battlesStore.types';
import { BorderRadius } from '~/constants/common.constants';
import {
  BattleDropsTimeouts,
  BattlesSpinnerTimeouts,
  BattlesMulticastTimeouts,
} from '~/features/battles/constants/rules';
import type { ISimplifiedPreviewItem } from '~/features/cases/types/case.types';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const route = useRoute();
const device = useDevice();

const props = withDefaults(defineProps<IPlayerWindowProps>(), {
  ready: 0,
});
const emits = defineEmits<IPlayerWindowEmits>();

const { hasPlayers, ready, playerDrops, player } = toRefs(props);

const isHostClicked = ref(false);
const isStartClickedWhenNoPlayers = ref(false);
const isShowDrops = ref(false);
const isGoFromLink = ref(true);

const isJoinnable = computed(() => props.playerState === 'join');
const isWaiting = computed(() => props.playerState === 'waiting');
const isPlayer = computed(() => props.playerState === 'player');
const isPlayerWon = ref(false);
const isPlayerLost = ref(false);

const playerTempWin = computed(() => {
  return props.playerTempWin;
});
const checkPlayerStatus = () => {
  isChangeColorSums.value = true;
  // Делает проверку, победил ли игрок и прокидывает ее для транзишена внутри компонента
  if (showResults && props.battleState.finished && props.isWon) {
    isPlayerWon.value = props.isWon;
  } else if (showResults && props.battleState.finished && !props.isWon) {
    isPlayerLost.value = !props.isWon;
  }
};

const isStarted = computed(() => props.battleState.started);

watch(isStarted, (val) => {
  if (!val) return;
  setTimeout(() => (isShowDrops.value = true), 2400);
});

const showActionButtons = computed(() => props.battleState.created && props.isMe);
const showOffer = computed(() => props.battleState.created && !props.isMe && props.player?.id && ready.value !== 0);

const canLeave = computed(
  () => props.isMe && (props.battleState.created || props.battleState.finished) && (route.meta.showExit ?? true),
);

const canShowEmodji = computed(() => props.isMe && !props.battleState.finished);

const isLastRound = computed(() => props.animator.last);

const animatedDropValue = ref(0);
const { formattedValue: formattedSum } = usePrice(animatedDropValue, {
  animationOptions: {
    duration: 1000,
    easing: 'easeOutExpo',
  },
});

const wonDrop = computed(() => {
  const result = [];
  for (const dropId of props.roundDrops) {
    const item = props.spinnerItems.find((item) => item.id === dropId);
    if (item) result.push(item);
  }
  return result;
});

const roundDropPercentOfTotalSum = computed(() => {
  if (!props.roundPrizesSum) return;

  let wonSum = 0;
  for (const dropId of props.roundDrops) {
    const item = props.spinnerItems.find((item) => item.id === dropId);
    if (item) wonSum += item.price;
  }
  return Math.round((wonSum * 100) / props.roundPrizesSum);
});

const showDrop = computed(() => {
  const showWindow = isStarted.value || (props.battleState.finished && props.isWon);
  return (showWindow && playerDropsShow.value.length) || (showWindow && isGoFromLink.value && props.animator.round > 1);
});
const showResults = computed(() => props.animator.played && props.animator.last && props.battleState.finished);
const lastRoundSpinnerFinished = ref(false);
// отображаемый массив дропов
const playerDropsShow = ref<IShowDrops[]>([]);
// функция, которая изменяет массив дропов
const changedPlayerDropsShow = computed(() => {
  return (arr1: IRoundSkin[], arr2: IRoundSkin[]) => {
    return playerDropsShow.value.unshift(
      ...arr1
        .slice(0, arr1.length - arr2.length)
        .reverse()
        .map((el) => ({ drop: el, key: keyDrop++ })),
    );
  };
});

// смена цвета суммы игроков
const isChangeColorSums = ref(false);
// ключи для корректной анимации инвентаря
let keyDrop = 0;
// для показа лоадера после перезагрузки страницы, до формирования массива предметов
const isLoadingAfterReload = computed(
  () => isGoFromLink.value && !playerDropsShow.value.length && props.animator.round > 1,
);
const isBattleWinner = ref(false);
// определяем лидера на момент падения дропа, чтобы перекрасить цены
const isLeaderNow = ref(false);
// делаем свой массив для показа, добавляем в него ключи, с задержкой от прихода массива дропов

watch(
  playerDrops,
  (val, oldVal) => {
    // при показе итогов батла цвета цены раскрашены, и массив дропов дополняется без задержки
    if (showResults.value && props.isWon) {
      isChangeColorSums.value = true;
      playerDropsShow.value = [];
      changedPlayerDropsShow.value(val, []);
    } else {
      if (!val.length) return;

      // начинается увеличение и показ дропа
      setTimeout(
        // при мультикасте нет показа дропа, звук увеличения дропа только для рулетки
        () => !props.multicastMode && emits('drop-volume', 'show'),
        BattlesSpinnerTimeouts.defaultSpinner + BattlesSpinnerTimeouts.afterStopInRandomPlace,
      );
      // начинается падение дропа
      setTimeout(
        () => {
          // звук падения дропа

          emits('drop-volume', 'down');
          isChangeColorSums.value = !isLastRound.value;
          changedPlayerDropsShow.value(val, oldVal || []);
          isLeaderNow.value = props.isLeader;

          if (isLastRound.value) {
            isBattleWinner.value = props.isWon;
          }
          setTimeout(() => (isGoFromLink.value = false), 0);
          animatedDropValue.value = props.playerDropsSum;
        },
        props.multicastMode
          ? BattlesMulticastTimeouts.timeForDropInventory
          : BattleDropsTimeouts.dropDownInInventory - BattlesSpinnerTimeouts.defaultDelay * 1.5,
      );
    }
  },
  { immediate: true },
);

const isWinnerClasses = computed(() => {
  if (props.battleState.finished && showResults.value && props.isWon) return 'winner';
});
const isDropsShowClasses = computed(() => {
  if (props.animator.played && !showResults.value && isLastRound.value) {
    return 'hide';
  }
  if (lastRoundSpinnerFinished.value && !isGoFromLink.value) {
    return 'show';
  }
  if (!isGoFromLink.value && props.animator.round === 1) {
    return 'drops-animate';
  }
});

const presetBtn = computed(() => createPresetor(changeXL));
const presetLeaveBtn = computed(() =>
  createPresetor(changeXL, {
    sizePreset: {
      height: changeXL(32, 24),
      type: 'default',
    },
    typePreset: {
      default: BorderRadius.XS3,
    },
  }),
);

const textStyles = computed(() => ({
  color: Colors.NEUTRAL.WHITE_50,
  size: changeXL(FontSizesNumber.LARGE, FontSizesNumber.SMALL),
  weight: FontWeights.BOLD,
}));

const growDropsGroupMs = computed(() => `${BattlesSpinnerTimeouts.growDropsGroup}ms`);

const declareImageOnItem = (item: IRoundSkin | ISimplifiedPreviewItem) => {
  return (item as ISimplifiedPreviewItem).image || (item as IRoundSkin).image || '';
};

const declareColorOfItem = (item: IRoundSkin | ISimplifiedPreviewItem) => {
  return GlobalUtils.Colors.getColorsRarity(
    (item as ISimplifiedPreviewItem).quality || (item as IRoundSkin).quality || '',
  );
};

const handleHostStart = () => {
  if (hasPlayers.value) isHostClicked.value = true;
  hasPlayers.value ? emits('start') : (isStartClickedWhenNoPlayers.value = true);
};

watch(hasPlayers, (value) => {
  if (value && isStartClickedWhenNoPlayers.value) {
    emits('start');
    isStartClickedWhenNoPlayers.value = false;
    if (hasPlayers.value) isHostClicked.value = true;
  }
});

const handleHostStartBots = () => {
  isHostClicked.value = true;
  emits('start-bots');
};

onBeforeMount(() => {
  if (props.battleState.finished) {
    // Чтобы не запускать анимацию победителя-проигравшего уже в истории сыгранного батла
    isPlayerWon.value = props.isWon;
    isPlayerLost.value = !props.isWon;
    // Чтобы прокинуть итоговую сумму дропа каждому игроку в истории сыгранного батла
    animatedDropValue.value = props.playerDropsSum;
  }
});

const readyOtherPlayers = computed(() => (ready.value === 1 ? 'start' : 'start-bots'));

const priceColor = computed(() => {
  if (isGoFromLink.value || isBattleWinner.value) return props.isWon ? BattlesColors.WIN : BattlesColors.LOSE;
  return isLeaderNow.value || isBattleWinner.value ? BattlesColors.WIN : BattlesColors.LOSE;
});
</script>

<style scoped lang="scss" src="./PlayerWindow.scss" />
<style scoped lang="scss">
.player-window__drops {
  --grow-drops-group: v-bind(growDropsGroupMs);
}

.player-window {
  --price-color: v-bind(priceColor);
}
</style>
